<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-calendar"></i> 服装
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{componentTitles[activeStep-1]}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="step-wrapper">
                <el-steps :active="activeStep">
                    <el-step :title="componentTitles[0]" icon="el-icon-edit"></el-step>
                    <el-step :title="componentTitles[1]" icon="el-icon-guide"></el-step>
                    <el-step :title="componentTitles[2]" icon="el-icon-upload"></el-step>
                </el-steps>
            </div>
            <v-base-edit v-if="activeStep==1" @nextStep="setCurrentStep"></v-base-edit>
            <v-param-edit v-if="activeStep==2" @nextStep="setCurrentStep" :product-id="productId"
                          :is-edit-status="true" ></v-param-edit>
            <v-image-upload v-if="activeStep==3" @nextStep="setCurrentStep" :productId="productId"></v-image-upload>

        </div>
    </div>
</template>
<script>
    import vBaseEdit from "../components/ClothingBaseEdit";
    import vImageUpload from "../components/ClothingImageUpload";
    import vParamEdit from "../components/ClothingParamEdit";

    export default {
        name: 'baseform',
        components: {
            vBaseEdit,
            vImageUpload,
            vParamEdit
        },
        data() {
            return {
                activeStep: 1,
                productId: undefined,
                componentTitles: ['基本信息', '规格参数', '图片上传']
            };
        },
        methods: {
            setCurrentStep(step, productId) {
                this.activeStep = step;
                this.productId = productId;
            },
        }
    };
</script>


<style>
    .step-wrapper {
        margin: 10px 40px 40px;
    }
</style>
